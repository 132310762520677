import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/web2/gatsby-starter-blog/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Providing talent for AI`}</h2>
    <p>{`Werkit has a good business model and an interesting take on the outsourcing concept: by delegating tasks to highly educated personnel in third countries they can support the development of AI startups as well as improving the work environment of Africa.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "626px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/642ab99eea0b27d69a08972e08ead2ca/af590/before-after_11.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "51%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABs0lEQVQoz42RvW7UQBSF9yloaCgpaHgAgpCQaIhEQQFI/LwHRMEUEQkNFW9DTRUUCSIKCrJKdq211/ZcO16vPfeci8YZlCgKiJGOND7385l7ZyZO5IET2ZO63l5keTJL02Q2T5N8uUycyFsnsuNEbjiRm07kffDKqkrmkSvKMnG1bDuRXSdyd1I3zae6aaw5PbV5mtqvo6kdTae2yLLRC7W6ae7XTfP4D7csCjs+mY1sUZYXuZ0JgF2SRrKjmbcokgPJPtRU9Q6Ah+Me7EEOI3POdaEG4PWE5Ac7WwHiJSGCGwpsRk6v4EITgdsaA8NPzbD2Vd+xU7V68Fb2Sg8i8IPqhhk3W6/2+bjSg0XFw6ywH/nC8tZx2eZ+NbQhcyuMPHb4U5b+a5FzvxQ7qDrbr3quNASa9TFw5WFf0lq/Z46HWWnfFnM7kZxZm/lBh3B2DOTZyKCxU4TOuA7DkrB/jLz2nu3QsfOdj+8wjrwXPszYh2u4II2PMwYC2Bz3ZPB0POyc7SP3JnT4Mab/VQDuAXj0H9y7iareBvAKwBMAT6/Qc+/9NVW9DuBF9J5dUvBequqt3+I04KSv9njNAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Before and after",
            "title": "Before and after",
            "src": "/static/642ab99eea0b27d69a08972e08ead2ca/af590/before-after_11.png",
            "srcSet": ["/static/642ab99eea0b27d69a08972e08ead2ca/772e8/before-after_11.png 200w", "/static/642ab99eea0b27d69a08972e08ead2ca/e17e5/before-after_11.png 400w", "/static/642ab99eea0b27d69a08972e08ead2ca/af590/before-after_11.png 626w"],
            "sizes": "(max-width: 626px) 100vw, 626px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`Brand`}</h2>
    <p>{`The company had grown very fast and the marketing material, although functional, lacked finesse. The overall sense was of stock content that had been hastily put together. `}</p>
    <p>{`Although the original colours were maintained the brand was fully reworked using core values based on simplicity.
Paired with a humanistic illustration by `}<a parentName="p" {...{
        "href": "https://www.behance.net/magrat"
      }}>{`@magrat`}</a></p>
    <h2>{`Web`}</h2>
    <p>{`The content was rewritten using a more personal touch to remove the coldness of the service and include key search terms. The website was redesigned prioritizing SEO metrics after researching competitors. `}</p>
    <div className="row my-5">
      <div className="col-md-6">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/0d0820a56908e02067e2d62066359674/df56e/werkit_2.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACG0lEQVQ4y61UXW/SUBjm5/kTvJpsaJyLIzExmt3pH/Bi6L3GyK1RMVlUpIBQMCxjMMZH2wM10HYrtNBS1nN6PkxpYai7IJtPnpyc8+Z58vY57dsIuwEiNzWTBTAheG1cpzOlPi877++/3L6/s/d0bzf+aDN2byO69S/vbMaiW3c3orF4fPfBw51E4pVvxggdlPl32VwyX0rnChkum85w37nsKjPZ3MGXrx9Tn9MZ7snzF7dub795m/TNCCHLcS4Ym6332Mr55FQcDLQzhGAEQmhZlh+GYEoJQsi2p+RPUMYU9Ww4MueZ/cSGabquOzfbNmMME8IYk8Qu9y0/NidLXbDq3V+jvurLsC8bGUZotufmQGSZY+HoxDTHy0qImcdc74rOjuMYxkiWewDItePTk1qr0xEURdE0VdM0VVVFsVdrtOsNQRRlWZZd1zXH48vOEEJV0fnCYcByqVqvN4ROp91sAtAr89UiX+H5SqPWdhyHUrqa2b8wZwr5wlHxx2EhV+GLR9ZkpjuGBW1rMs1xP8vFairFvf/AGaZ9RWaEPFGQOy3QbgFRkDEmmj6cWLZ7AVtNAAT5dfLT42eJfKnKGBuOFmbTND0PQ4joAoRQCBH2sIcwQl5QDC4u+LDPdd03U0oHA0USRCABCXQDihIQgX8Ec4Z1CYgSAPNNvz8ghCwHgy5WutivHkPScCxCTeTv97nubNH/8TO4tvk3C9VRm36YaLoAAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Old website",
                "title": "Old website",
                "src": "/static/0d0820a56908e02067e2d62066359674/5a190/werkit_2.png",
                "srcSet": ["/static/0d0820a56908e02067e2d62066359674/772e8/werkit_2.png 200w", "/static/0d0820a56908e02067e2d62066359674/e17e5/werkit_2.png 400w", "/static/0d0820a56908e02067e2d62066359674/5a190/werkit_2.png 800w", "/static/0d0820a56908e02067e2d62066359674/df56e/werkit_2.png 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
      <div className="col-md-6">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/8ecc45bc410f2bd5e31902e6c17434a6/df56e/werkit_3.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACkUlEQVQ4y63TX0hTURzA8bNmU1eic9b8A9GjfygLrAwikB6EihB66KXAEvvzMiyd+RBBL9VD/mmik6AMt9G0tlyb3baZ07Hdu012bXfd5tyWtUov0tyLA7d7thMbI0aUhHr4cDgczpff0wFoCwtsNYYQshBCmGDTh/8BIczEybRNTpZK+1uv3uiQdInFN1taWpubr2yg5fq1S82XpdL+dAzjpwbVOXeeHu4dLbz7DHQO5XSlcG8PAYksGye1D9W3y/I7n1wcHkdJCFB8vWmW2eWIVjjXRM5osTMqdEb3zkZFs6k9W2n6cp99LY+ItruXEbueis87vhfYImX4ahkernJGKh2Rcjxciq9mE1l/FlnDJfhqBRHOt0U6P/xAMAZQPNZkXcybYvaYl4vNTLV1pRZfKTEzxeZl4W9TS2WWlXM4c3JmqWia4b1nJK4QSsRT8dnpgAD7csAQqDX4azB/NeY/aAhUvgvsN3wuNISKjN8KsWDFC9cj/BPm/VptCoG3oQ7nYiY+M+njaQPlOl/VG7pGT1fq6UO6j+U6n1C3UKAL8vXBKn2w7oJacuu1A/fcmwrkaYNtRBAlWYDY2GkDDV7NCzW0SO05oiTrFaRI7RGoab7Gy9fMC8Z9x3W+ow9tDSOeExN+gdYHxrxttoVM3DjhBkqKP0rxVFSdzHFswL5TReVm4aqoHWMU56WHq6J2qyigcItnvAhBgNh4o5YEz8l8BcmTkxzlHEc5x5OT2XLlZO6IK0VO8hUkGHaJzXQmblDhQDrDlVnAoAUMpA3+U47MAh5Pt2IkQgmAUFLjoh9gth4T0W38ix4T0WeydxuJXhPRYyT6Ju33MavRPb8dX5JNJGOJxIb+fAATye2YvOn4F4nVhIkSfg9aAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Main illustration by Marta Leshak",
                "title": "Main illustration by Marta Leshak",
                "src": "/static/8ecc45bc410f2bd5e31902e6c17434a6/5a190/werkit_3.png",
                "srcSet": ["/static/8ecc45bc410f2bd5e31902e6c17434a6/772e8/werkit_3.png 200w", "/static/8ecc45bc410f2bd5e31902e6c17434a6/e17e5/werkit_3.png 400w", "/static/8ecc45bc410f2bd5e31902e6c17434a6/5a190/werkit_3.png 800w", "/static/8ecc45bc410f2bd5e31902e6c17434a6/df56e/werkit_3.png 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
    </div>
    <div className="row my-5">
      <div className="col-md-6">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/8297efc06d411300e2724655a406356a/df56e/werkit_4.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACH0lEQVQ4y8WTy27TQBSG85JQtVWqKrSAUBe8AiwIKRtgURb0Ai+A2LIFUwipbDZNaxEpbKicxpfx2J6LzzkzaBw1hKi7IvHrl/XbOt94zhlNy95ArZvCUuqcVzmveLHsohS8WDbLS6m0g40xz3rd9tqtB3c7252Nrc317c7Gdmfj4c69nftbdzbX22sr7bXbc2+2V9urKy+fv3BwXes37waP97yn+196B/1Hr46fvP7aOxzsHp3svvW7Ryfd/f6ie4f97oH//kNgDbS0Un7IBiPbD+n4nD4NyTsjb0jeufEGzPvGvNB+PjOL/ji0wfkEQTm44Llr35iFWTSZXdhi+ud1lpqyOJ5IqVpKqSxzMKFBsmTcIgCYJmkSJ5PplOU5GYvkvhsXHB1FkVINnOf5fEmonRBAikpIWQpZCVUDGTICoKiBEK21l5eX18BVJeI4UUoKKYVUlcBSIq/AkKkAmK6xgdM01Vovw0ppzgsiQkRARDKApgYzzcAYmpddDyPUUkoAcMMhqrUGqI21SVIXnIdhOCtO0+Q6WKlJFGUsB0Aoi4uLX1E0cT2rkrPM9/0kjv+CGWPNb9yuaq2TlKWMS6EqoXgpc15VQmoAJFJKzTa1DJurcyYyNRIawqZLsqbJtHjOWZa6aSPieDweLWh4ehoEfhAE34OZ/NkzDMMfjUaj0c/xGBHdrRJCMMbyK/FFFcU8zgsYY1LKf3Gf/w/8G/6WVVNBchxjAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Old website",
                "title": "Old website",
                "src": "/static/8297efc06d411300e2724655a406356a/5a190/werkit_4.png",
                "srcSet": ["/static/8297efc06d411300e2724655a406356a/772e8/werkit_4.png 200w", "/static/8297efc06d411300e2724655a406356a/e17e5/werkit_4.png 400w", "/static/8297efc06d411300e2724655a406356a/5a190/werkit_4.png 800w", "/static/8297efc06d411300e2724655a406356a/df56e/werkit_4.png 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
      <div className="col-md-6">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/3a7e20778b00897bccea3b87d793a053/df56e/werkit_5.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACeklEQVQ4y8WTTU8TQRjHe/LjeCDGBBOPXrxLQwUBXyDiF9AYo6mGAmIMEQ+SouABjSjdLRrFcNJEo1G73VIgFtxoNLyl3ZmdndmZ3Xkx05VCFE+Y+M//8GSe55dn5pmZhNqDEnuFScCA50MPayPiIdww8okXG20bQD+gTMNSyv5MXyp5rOf0qa7Ojo721MnOE10d7ed6e852n+lsb0slW1LJltYttx1vbW1JDt8c1jCP2OOXxVsPP92ZtrJGaeRRYcy0x2fKE0/L4zPlu2ZpzLSzOXt0ujias0dzdtaws+bi7KuSFGFCKXZhEuy/KA5fDQ9eiY4MROlpdnkquGaKgWcqM6NuvFC998ID573mdNScjg6lw6ZLPDu7rhRNSM6mXm9mDDIyi4ef434DX8/7g3l/KI/7TNhvoqE8HjT9jOFnDJwxdEFfLpj78ENxmqCUQgiVUlLKnZOUSnzm3xy+Wo+3U3HZ5uZ6QOswABoWQq/H5lyQIFgC37/A1YhFOiOVkCoUMuRCKbWxsUHpXzoLzhFwfei6AEAId6biuFarMsZ+hzH2CSFhXSzkQSgDJlHAlVJzX2tHH7zrfvIeMe4Bd5fOvo8BAFEUMUopYwHjhIoYnqjU9t1+03T/bZVyBHeDNcHYny9xrcoBCddIuIp1tubWfm0bAFAfmJ4EotgFgFKqny1niGDkeVIIiCMaRY0e1caZwY7OIWeeBxmLhFBcMEx81wWUcaWEkqJR5ro1vW3OueM4y7FWVpYWFyzLKlhWab5s2/OF4vzHgm0V7cWFheVKJa6qVCqO43DO67+KEFC/Ei1PCyHkxYGOtaHnwS0BAIIg+Bf/+f/APwFzfjt1Mm4a9AAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Refactoring",
                "title": "Refactoring",
                "src": "/static/3a7e20778b00897bccea3b87d793a053/5a190/werkit_5.png",
                "srcSet": ["/static/3a7e20778b00897bccea3b87d793a053/772e8/werkit_5.png 200w", "/static/3a7e20778b00897bccea3b87d793a053/e17e5/werkit_5.png 400w", "/static/3a7e20778b00897bccea3b87d793a053/5a190/werkit_5.png 800w", "/static/3a7e20778b00897bccea3b87d793a053/df56e/werkit_5.png 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
    </div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/60664994ecac55d2e7ce2324835ace1c/df56e/werkit_6.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACV0lEQVQ4y8VTz08aURDmn+of4KG31pOXJm0PVk+1aXrUNGmqlUhjUmM11YohYEpqiwhUSf2FEVvbRqAgiKAQcBcXEBB3F3aXffPea3ZB2xoTD23aOb2Zed+bb+Z9Y6B/YIZ/Dya/gTHGBBNKCAA03V9utoJEM4wxBkBXV66LgsDzRK9DCLmMth5kc0dVllNrtdW1NUlppA5SqiRTShVR4ovlgH+DUsrzlXzxMLEbnp4YbHJpVZ5+MZbe2QVB3PZvgtw4iMRlRSYEMpF4OhiyvzKpKpwUC9FvX0aeP3bOTvykTQiti6L2GGCqQuzzptdqfhla7+1/1HG9rd0z1ea29Fk8+0yEY6JHX1dIJYb1Rgz6ADQO2Uymt68vurfndXtsZutKIj5uGx7outHlstz2Lxut79mF5eTS0s32azNmrTIGZJAlSZG19rKHjNFojCbiXGa7mitQShtywzU2nkoeUEozCW7qycKKPf5ufnZr1RwKBkulSqtngZKKPs8jNr2+6hHLbLfN3vthwz6/eOve3dGR4XAg8tHh46uNIvPd63s9Yx0Va5IGRgClcpllc6eCUOTykwNPna65ntHJrVRufyf0sOe+0TToC3wKx5IAarV6WpOAnA+sXpfmXF42y+ZL5e3j7IPOjgXHmyajzn7jkOnZfiF8x9HtiC5SSlWkYowwPvsqBOj4uIxBcWdiLoYBHQYYEwCnz//W6UJILoh5rsJhgMu1TSjOQ61xpkMNj1Dz0HIxXNCZ4Uy/5Fzy53lFlqsnJ4CQnsdXbBWmlyn4wir9333+G+AfFIRQ0z7ji9cAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Refactoring",
            "title": "Refactoring",
            "src": "/static/60664994ecac55d2e7ce2324835ace1c/5a190/werkit_6.png",
            "srcSet": ["/static/60664994ecac55d2e7ce2324835ace1c/772e8/werkit_6.png 200w", "/static/60664994ecac55d2e7ce2324835ace1c/e17e5/werkit_6.png 400w", "/static/60664994ecac55d2e7ce2324835ace1c/5a190/werkit_6.png 800w", "/static/60664994ecac55d2e7ce2324835ace1c/df56e/werkit_6.png 1188w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/cadc21994da317c6a2010a8e9f0d3f2a/df56e/werkit_7.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7EAAAOxAGVKw4bAAADkUlEQVQ4y6WUTWwbRRTHG4kbJ8SFHLlQKUJIHKq4B6ioCBw4cUEgBQUKSIj0gCoFpLqECEVqlFaAFAlSEIiWALUT7BSvvzepkzpx3HzYThzbaztObMde74fXu7Of/tgZtHYSG8GNOYx23uzvvf+b92bOof8xzhkTRLCFmvWW3oQIIk1uaGoDIaQ3dUkEgK+JAg/4mvHBc5oiMQzFC8IJ3Ky3Urslpgx4WgasypRAlRRrlMTTcrlwmCXixH40ndxNJ2KZ1B5LFnCfZz+ZOoEbWitHUIpQ52lZ4lSekY9z1b2dQjHHlgu57XBwI7iSjEeIRCwZjxZyxI8/3NmJxrqyRU6VOY2n5brYqIvN/AFzkKzUKOmASDwK4IEl79rqUmRzI7odTidik5OT0Vgbhu3U2TJQQJ0lQS5FAVatkiJdEhhSSMZjj0OruNfpwhZ9bsey3xUM4KOjn2SzmW5kVWhUivxRhknEjvU6zOyTqtCQOPWASG4EA27MHljyuDH74p+WZb9nZGSkQpZPYL2pC4xSoyT6WMgkyHyWCa9liXhJYOTdna2N4EPc63xgsy7cn/O5/7r3y08fvP+eLIunkXXEMzJgFYnTBFYBrEoV+XyGOcrQkceh8Fpg2e+yL/xhX7iPe7GfZ2duTpg1Te3KlmsaUwJcRUQtRJeEcp6rFPnSYXX9USCAu3APhnsxv9vhdzs8DpsXs0uSeCYbSpxWKfKboSxHidHNo0yCBKxSznOh1Ye468H873e/+uLz62OfTpg/wxbnw+srAJw2id7QlVqdPhaYspLao3bCOcCqYlUDrBLwOh3WuaGXBp995qnXL1288u7bE9fHVpbcXRh1ygXRWjC0vRUxuhUaJqhDIPAiEIZeNj395BOmF58fH7s2/NabuXadIIQG3Gq1EEJOp/OiafC1oVdtNlvHCBHS21t3vpt559ILY8NvfPzRlckvzR3ytLebTYTQ6NWr396+Zflt7pXLl41cdP3sJ1GS75k/vHlt+HvfiiLw/wGPj48PXrgw883XJpPpbFvXddj2sh/Ef71lzlcqhhHq3SvZgaempvr7+60WS19fn8fjMQKKosVq9bowIr4bWvZic7Pp7WDb77/g6enp8+efu3HDPDAwEIlEjNvWaNAMkyVSyeiWb/7uwux0eme9fZA9cGdACCmqQpJlSZL++WAY+hVZOswmZVlG3fL0wL1eOgn3LPUzqb3P0N8w4/24cCRSZwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Refactoring",
            "title": "Refactoring",
            "src": "/static/cadc21994da317c6a2010a8e9f0d3f2a/5a190/werkit_7.png",
            "srcSet": ["/static/cadc21994da317c6a2010a8e9f0d3f2a/772e8/werkit_7.png 200w", "/static/cadc21994da317c6a2010a8e9f0d3f2a/e17e5/werkit_7.png 400w", "/static/cadc21994da317c6a2010a8e9f0d3f2a/5a190/werkit_7.png 800w", "/static/cadc21994da317c6a2010a8e9f0d3f2a/df56e/werkit_7.png 1188w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/54b8949a409b37af4121b430bd6b973f/df56e/werkit_8.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAErElEQVQ4y33LWVMaBwAA4P0RnU5n2j50pk99ad86OcwxBlg0JmnSdGw0pmp0xBhR0CjByOmtFQkqy7HKaKpJmygiLKAQSTilopxxl1sQUQdIYzTEROmkP6Df+wcIPOigx9/rDnDdwXZ3iOYKU12Rele0xrlR6YrddMWLnfGrzs0iZwJcS+S7kmfd26dXd8682Mh/9gr4v+yM3XT+l12bRZ4tgm8bb0sUTvsKh63ETi2BPgsIveiQ19/vCXR6gkxP6L473OQK17sjNa5opTtW5o0XuzevOjaLnkcK//ISh17i2+dwLAWeq8Sx5oA2G0ZfxlptgWZbgGIL1tlCtbYwyRaptkTLDeHrc+tX/nAVjViJHQsEhgLXLscxFQSOEs9V4dgK4Lw6eAoJ5SHBM+rQOSRQsOAnTq1c5L+4JLBc7NODzHkcQ45nzxFYchxjhsCaI7DnQdY8yFXmM2eBkvElqspYo7BViJHqJ4aTCuxH3uJpmeUkU3GCLs/jLuC5qgtc9ekHc2fbZi6wkQssxVmmIo/+7FTjGPBsavzYJDheFeWsw2+t4nmjqkujr1GZ78OPJB2MLjrzStvkFarwLoVzuRUGG4XnKWOFDQLCHR6xSQVc0ocajb4h26r31dLR+tSuaSz+XJRbHT5ehXKG7o9/3rOKmauDpOTwbbSv3NNVNvGgsb+hqoPK/YnuBHAIlocE8jThm4awcNWvsBi0utmPrsmPDuGHNckHp+TYITi2Cw7N/Kyu63CaggobSO3jRS1mQv0cQETQAjVaoEFBLVZhCD9YjrfZYzSz3+Y15Hyy98sjWTt0uCI6XBNn18T7Jv7eJDXCJ/fQOr6rhj5lUI2CCEpUo3gELdRiQ47kpcVAjTFm99uOsOn3DknWDh3oe9+ZeYcucVrZeSSpmejlfF4yABBVnyZBjYJqDNRiOA3GsSdaLNFKQ1S1Hv4QUx77xvYQtn2A9No+8X4Fzj5uTonqt6BGdTcFIGhQghYDNRhBixEWsaLFQJ0xQrfGq8zJRNybDc3E7Aorrxlh31GKeBwaTdbT8g4mpyFyVloPgFoU1GAFGixfs35V579njpJNUY4j9nxZt+eb1i0+OtEjv9vErCC1FpXd/6FJXMaT7EG1aXHDLkQGQA0KIuvnVShRG7imi1DNW1RLwuT3jsCCJ6pp6uOZrxlT+SwpSBOdu8Eu7Xs0SGsI9ZS8ljamoHoAjwQuL0QY9g0luqHHAkvrbl/QkgyrtIrJtJ7f+VR+HdZUdIrI7EFhc9Vs229e1i/bI6Td0drowC2g3xFY8RiyvqdH7omcW5JziY/ccM47/kbXm1JydkxiuWxE1d/i7K5IDFVmoDsZKSUlrIv2lwUGSoB912TOPrpvgg5s0i2jLGsfO/pbdGCDDpahNwb+66XRAxP8zij9Rzu8K25KicgZMTn5sHq7/9dd3g3g2AqtL8huDcyWcmQn6kdrOaNeBD60wntG+K1xLPNSmjJIMktw2gCnZS1pYd32w+qdgVJtJ6WVxgWi+vGy32e/uA1/VdzxzTXGlz93fFsxxB8e3X8JZwySjEmS0kO7ioEdWUuSXxXpLw92l+oY5d+TRj8r7vsXfHAC+lU03doAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Refactoring",
            "title": "Refactoring",
            "src": "/static/54b8949a409b37af4121b430bd6b973f/5a190/werkit_8.png",
            "srcSet": ["/static/54b8949a409b37af4121b430bd6b973f/772e8/werkit_8.png 200w", "/static/54b8949a409b37af4121b430bd6b973f/e17e5/werkit_8.png 400w", "/static/54b8949a409b37af4121b430bd6b973f/5a190/werkit_8.png 800w", "/static/54b8949a409b37af4121b430bd6b973f/df56e/werkit_8.png 1188w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/61f3c66a8f37c0f20f03fba4bf2b7b18/df56e/werkit_9.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAC4klEQVQ4y5WU2U8TURTG+ye4JCY+6rMhxhifSPDBxBjURAn6AIFojKTiQmIAjWgjGkTCvociWykWWi0Oa5tSu1CkkU2kglQKdLEL04JQZqYzc+eYdhKkpRg9LzeZe3/5vvvdc0YAkeI4Dv6/BPyCEPq5/svowK34BkUQIZr+J5jXXLE7HjVJDooNz4zzPpcjSFJ/juzvSYAQAgClSiMsKOy0OLumFme//9iBOY5DDOJY9Ddltd4oKirO6Pt8tG44u7DE6/PFBMFBnFTCdyYpatXlVmn16kVHQe/HpNT0FbuD3952b5teGRfUM2EeoThwiKYVvdjd/CfH6wbPSAyStzKfP8Bveyy4/HKPQlSxRuCIN7PLgYCP2uF0zli+qWwes3eLIikOIRo4W8Dv8vtZLz296pTavgbWfTgR5PY+VUxRLEMALMzNSyvFmwHvptejtFv7jSqffdnnW+WT3PXOHIcQYjmOjRjzhwgAqJmy54ixscFhvUyh1ekr5iaaJozYrImMkFxc5W2GCSF6OcgkiHUt1Q1fRkZKMb17bb19eS5PN2RwOzbo0L62gwwNwM7gxNmusfJambyt2zSxBAAvW9qPJJ7T6EYRABlpwTgwidhtxNBMON07ok+HEmoKinQAUFjVePhU4oWbQu/WFp98FIxQ+DIms6espB8A/JtERuaHi5fe5xaPA0DZmw7h0xft77ArWdl800bDkSRM1tWaIU0IbZkxbU8mprmt7hXpgAEpNpCekw8Ar6vLteqBWJhvALl7qdIgN+i61KWdAxl9w1nD4uQm5QNlt3woLfcxETnZKld6cVwQMzwUQxeZR4WivIf3biVdvS5KLu261l2VUl+f0txQ0dKskAIAyzAkSbEsK4iRJUP0+RtZgmMnDpxMPJ2aJkzL6cmQWCZtS1aXcXzS6XHvNEWUbR72+AMymaSytur+8+JSccfstLmtsZXebyT3fImaPASwSVEMw/KCMX+r37tgGMZNt08eAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Refactoring",
            "title": "Refactoring",
            "src": "/static/61f3c66a8f37c0f20f03fba4bf2b7b18/5a190/werkit_9.png",
            "srcSet": ["/static/61f3c66a8f37c0f20f03fba4bf2b7b18/772e8/werkit_9.png 200w", "/static/61f3c66a8f37c0f20f03fba4bf2b7b18/e17e5/werkit_9.png 400w", "/static/61f3c66a8f37c0f20f03fba4bf2b7b18/5a190/werkit_9.png 800w", "/static/61f3c66a8f37c0f20f03fba4bf2b7b18/df56e/werkit_9.png 1188w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/ee09862be55b28fe9b4d53eca61fb31e/df56e/werkit_10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACe0lEQVQ4y8WTS2tTQRTH8ylcWREXFSqKC1ci+ADbKoi4EUFXfgIX0p2KFHHhTnGhbUwVdGELBRGK2neFUrS0tM2LNE3b5OYxc29u7itz5s7jyE1aiwoudNHDn2H+M+fHmTPMxPA/IravsOc4FqWubTv1um1Si5C/yCQ1Uq24TmMHvnOv/2T31Z6bt89cu3H8wuWusz1tHftDXed6T1+8cuL8pbsP+luwDK+PzHUOzfUOzx9NTB+OT3UmZjoTM0fi0x0DU4cGpg4OTLbV8XLywOBk9/PxU0/H+758Qy1jnINBzA3aWN0q54llBkB9MANoTRj1mc3CBogGCLstFrqhqlCLc4gBgEVpyEMphRQhhCGDKDhAGAoGXCmppUSltNItKdTapBSgBRNCETG9Xtqs1MxiAYCj1ohYyy1DI9oSQiAiLdLSdhkRlcZyuQLAIpjSKOPtyPxKdtsoZBzHRcRsuhJ/ODKbTTpaoJSIOLG08frTRMrYRsRiqcQY26u8Or+4WTDGZhcNUkfE/vufn/UN3noUH/uaQ8QcMQaffHjxeHQhtYyI1Wpt59iUmlrr7PelummXa04QcER8834l8erj6kKysGkj4lo+925omG4QhVopTQjlP2GltR00Lc+vex7wUCkFAgIOLmdMcBFdpvKFqDeDoMmUxj2YtHomllUoGkaNhEJorW3HKxRLW0YlYKCUElLWTLNKTCHkL5UJNRGxycC2G0EQKKWl0lIq13WDZlNrLaTSGn0/8LxoVyMSakY9SynX8/m1ZCqVzqQz2VQ6k0yl2kpnMpFNtmw7Ydfm83kpZfS2AcDzPN/3/fa4F7/Z3VXP45zv+3/+Z/gH0r42CC7aOwcAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Refactoring",
            "title": "Refactoring",
            "src": "/static/ee09862be55b28fe9b4d53eca61fb31e/5a190/werkit_10.png",
            "srcSet": ["/static/ee09862be55b28fe9b4d53eca61fb31e/772e8/werkit_10.png 200w", "/static/ee09862be55b28fe9b4d53eca61fb31e/e17e5/werkit_10.png 400w", "/static/ee09862be55b28fe9b4d53eca61fb31e/5a190/werkit_10.png 800w", "/static/ee09862be55b28fe9b4d53eca61fb31e/df56e/werkit_10.png 1188w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/a89a539b69b723d3e8f08b1ffbaad0f6/df56e/werkit_11.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACK0lEQVQ4y42Sy05UQRCGeQG2+Ag8gDyGK99AV64MC01YkKiJiQu27jRxx0ZD1JAQSDQhEgkQ5BpkBI4wDDNnzpk5tz59qa6qbsMZJagzwJ9KpzZf6q/6e8j/Lee9c/5/uar+0dBFh8yKCJgtsya6XArREJleY20fGIgNU12anVQWaBWhItLOaWbwPkbXRVaICqAPbKvJEqlEAnaGXWFMR4goTde3thZXVveCALzvDwMxMAVCf4nS3aIbYt7sxCvr32bn50dHR2+NjDwcH/feC6UGTs6RQmPrqoyt/NlsHTUay2urt8fGhoeHH09MXAVLIkTwVjvmPzf2knDnoLa2uVkPQ+1cf9uGyHv3uta6M7d9f6X+YObTvZfTU4c74depomOrOFyJqAfBzrv3Qfhk/fjFduPZ3NLk9Oyr/e0fy2+idqkYpbVyENyzrcEQAjOyY2YCawv0pjpHlcUgmEgzn6V5rdWuhVFcKkkUlTLM0kQq/TvIATAQGccncXcjOP7eaB602onSAiDTWlh7o8mtLA/TTCECEVff68LzlXAvZ61320lJtJfKxWYmLRQA+lr43Lb3aZY+erfwdmN/cnnv7oelTp4HUdzKilaWFwDVRQfA2vsk6T6dWXj+8fPp2enhSdDM8uO400jSSJQl4jW2pbUnSZ7jucPCkgAorRXWCrDX7ywACq0zdZ6T7K3KrJgzS+VVOyMKAGFMoXWhdQlwuUTvNab/33Y31gX8C8vlcFJrIwuRAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Refactoring",
            "title": "Refactoring",
            "src": "/static/a89a539b69b723d3e8f08b1ffbaad0f6/5a190/werkit_11.png",
            "srcSet": ["/static/a89a539b69b723d3e8f08b1ffbaad0f6/772e8/werkit_11.png 200w", "/static/a89a539b69b723d3e8f08b1ffbaad0f6/e17e5/werkit_11.png 400w", "/static/a89a539b69b723d3e8f08b1ffbaad0f6/5a190/werkit_11.png 800w", "/static/a89a539b69b723d3e8f08b1ffbaad0f6/df56e/werkit_11.png 1188w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      